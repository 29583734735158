import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useAccordion } from './util/useAccordion';
import { colors } from '../styles/config';

export interface AccordionProps {
    title: string;
    titleComponent?: any;
    initiallyExpanded?: boolean;
    controlExpanded?: boolean;
    onToggle?: () => void;
}

const Container = styled.div`
    margin-bottom: 0.5rem;
`;

const Title = styled.h2<{ isExpanded: boolean }>`
    padding: 0.8rem 2rem;
    border: 1px solid ${colors.grey13};
    border-radius: ${p => (p.isExpanded ? '8px 8px 0 0' : '8px')};
    background: ${colors.white};
`;

const TitleButton = styled.button`
    font-size: 1rem;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: ${colors.blueDarker};
`;

const Region = styled.div<{ isExpanded: boolean }>`
    border: 1px solid ${colors.grey13};
    border-top-color: transparent;
    border-radius: 0 0 8px 8px;
    display: ${p => (p.isExpanded ? 'block' : 'none')};
    padding: 1rem;
`;

const Accordion: React.FC<AccordionProps> = ({
    titleComponent,
    title,
    children,
    controlExpanded,
    initiallyExpanded,
    onToggle,
}) => {
    const { expanded, buttonProps, regionProps, setExpanded } = useAccordion(
        controlExpanded ?? (() => initiallyExpanded ?? false),
        'Accordion'
    );
    if (typeof controlExpanded !== 'undefined' && controlExpanded !== expanded) setExpanded(controlExpanded);

    const toggle = useCallback(() => {
        if (onToggle) onToggle();
        else setExpanded(e => !e);
    }, [onToggle]);

    return (
        <Container>
            <Title as={titleComponent || 'h2'} isExpanded={expanded}>
                <TitleButton {...buttonProps} onClick={toggle}>
                    {title}
                </TitleButton>
            </Title>
            <Region isExpanded={expanded} {...regionProps}>
                {children}
            </Region>
        </Container>
    );
};

export default Accordion;
