import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, colors } from '../styles/config';

function BlockBase({ largeSpacing, withGradient, as, children, ...props }: any) {
    const Component = as || 'div';
    return <Component {...props}>{children}</Component>;
}

const Block = styled(BlockBase)<{ largeSpacing?: boolean; withGradient?: boolean; as?: any }>`
    width: 100%;
    padding: ${p => (p.largeSpacing ? '3rem 1rem' : '1rem')};

    ${p =>
        p.withGradient &&
        css`
            background: linear-gradient(to bottom, ${colors.grey2}, ${colors.white});
        `};

    @media (min-width: ${breakpoints.tablet}px) {
        padding: ${p => (p.largeSpacing ? '4.5rem 2rem' : '2rem')};
    }

    @media (min-width: ${breakpoints.desktop + 2 * 2 * 16}px) {
        width: 1366px;
        margin: 0 auto;
        padding: ${p => (p.largeSpacing ? '7rem 0' : '2rem 0')};
    }
`;

export default Block;
export const Section = styled(Block).attrs({ as: 'section' })``;
