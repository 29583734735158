import React, { useReducer, useState } from 'react';
import { lensPath, map, prop, view } from 'ramda';
import { graphql } from 'gatsby';
import Accordion from '../components/Accordion';
import { Section } from '../components/Block';
import Title from '../components/typography/Title';
import Text from '../components/typography/Text';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Main } from '../components/Main';
import { RemarkNode } from '../models/RemarkNode';

export interface FaqNode {
    question: string;
    answerNode: {
        childMarkdownRemark: {
            html: string;
        };
    };
}

export interface FaqProps {
    data: {
        datoCmsFaqPage: {
            title: string;
            noteNode: RemarkNode;
            seoMetaTags: any;
        };
        allDatoCmsFaq: {
            edges: Array<{ node: FaqNode }>;
        };
    };
}

const Faq: React.FC<FaqProps> = ({ data }) => {
    const [expandedTitle, setExpandedTitle] = useReducer(
        (current: string | null, next: string) => (current === next ? null : next),
        null
    );

    const getAnswer = view<FaqNode, string>(lensPath(['answerNode', 'childMarkdownRemark', 'html']));
    const getQuestion = prop('question');

    const nodes = map(prop('node'), data.allDatoCmsFaq.edges);
    const faqs = map<FaqNode, JSX.Element>(node => {
        const question = getQuestion(node);
        return (
            <Accordion
                key={question}
                title={question}
                controlExpanded={expandedTitle === question}
                onToggle={() => setExpandedTitle(question)}
            >
                <Text dangerouslySetSanitizedHTML={getAnswer(node)} />
            </Accordion>
        );
    }, nodes);

    const { seoMetaTags, title, noteNode } = data.datoCmsFaqPage;

    return (
        <Section as={Main}>
            <HelmetDatoCms seo={seoMetaTags} />
            <Title level={1}>{title}</Title>
            <Text style={{ marginBottom: '3rem' }} dangerouslySetSanitizedHTML={noteNode.childMarkdownRemark.html} />
            {faqs}
        </Section>
    );
};

export const query = graphql`
    query FAQ($language: String!) {
        datoCmsFaqPage(locale: { eq: $language }) {
            title
            noteNode {
                childMarkdownRemark {
                    html
                }
            }
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
        allDatoCmsFaq(filter: { locale: { eq: $language } }) {
            edges {
                node {
                    question
                    answerNode {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
    }
`;

export default Faq;
