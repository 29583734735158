import { useCallback, useMemo, useState } from 'react';

let UNIQUE_ID = 0;

export function useAccordion(stateOrInitialState: boolean | (() => boolean), name: string, disabled: boolean = false) {
    const uniqueId = useMemo(() => UNIQUE_ID++, []);
    const regionId = `${name}--accordion-${uniqueId}`;
    const buttonId = regionId + '--button';
    const [expandedState, setExpanded] = useState(stateOrInitialState);
    const expanded = disabled || expandedState;
    const toggle = useCallback(() => setExpanded(e => !e), []);
    const collapse = useCallback(() => setExpanded(false), []);
    const expand = useCallback(() => setExpanded(true), []);

    const buttonProps = {
        id: buttonId,
        'aria-expanded': expanded,
        'aria-controls': regionId,
        disabled: disabled,
        'aria-disabled': disabled,
    };

    const regionProps = {
        id: regionId,
        role: 'region',
        'aria-labelledby': buttonId,
    };

    return {
        uniqueId,
        regionId,
        buttonId,
        expanded,
        setExpanded,
        toggle,
        collapse,
        expand,
        regionProps,
        buttonProps,
    };
}
