import React from 'react';

export interface MainProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id' | 'tabIndex'> {}

export function Main({ children, ...props }: MainProps): JSX.Element {
    return (
        <main style={{ outline: 'none' }} id="main-content" tabIndex={-1} {...props}>
            {children}
        </main>
    );
}
